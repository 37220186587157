import React from 'react'
import Layout from '../../components/Layout'
import {Link} from 'gatsby'

export default () => (
  <Layout>
    <section className='hero is-large'>
      <div className='hero-body container'>
        <div className='content'>
          <h1>Thanks for getting in touch! We'll get back to you soon.</h1>
          <Link to='/'>Back to the landing page</Link>
        </div>
      </div>
    </section>
  </Layout>
)
